import { Route } from '@angular/router';
import { authenticated, unauthenticated } from 'auth';
import { hasPin } from './modules/uniform/store/uniform.guards';
import { hasJob } from './modules/pin/store/pin.guards';

export const appRoutes: Route[] = [
  {
    path: '',
    canActivate: [unauthenticated('/pin')],
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'pin',
    canActivate: [authenticated('/'), hasJob()],
    loadChildren: () =>
      import('./modules/pin/pin.module').then((m) => m.PinModule),
  },
  {
    path: 'uniform',
    canActivate: [authenticated('/'), hasPin('/pin')],
    loadChildren: () =>
      import('./modules/uniform/uniform.module').then((m) => m.UniformModule),
  },
  { path: '**', redirectTo: '' },
];
